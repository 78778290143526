import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'

import AppRouter from 'src/containers/AppRouter'

import theme from 'UI/theme'

import CssBaseline from 'UI/CssBaseline'

import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  pug`
    StrictMode
      CssBaseline

      ThemeProvider(theme=theme)
        AppRouter
  `,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
