import _ from 'lodash'

export default (url, params = []) => {
  let output = url

  const isLastParamQuery = _.isPlainObject(_.last(params))

  const variables = isLastParamQuery ? params.slice(0, -1) : params

  variables.forEach(variable => {
    output = output.replace(/:\w+/, variable)
  })

  if (isLastParamQuery) {
    const qs = new URLSearchParams(_.last(params))

    return `${output}?${qs.toString()}`
  }

  return output
}
