import injectVarsIntoUrl from 'src/lib/injectVarsIntoUrl'

const buildPath = url => (...params) => injectVarsIntoUrl(url, params)

export const to = {
  current: query => injectVarsIntoUrl(global.location.pathname, [query]),

  AuthLogin: buildPath('/auth/login'),
  AuthLogout: buildPath('/auth/logout'),
  AuthSlackCallback: buildPath('/auth/slack/callback'),
  RoomJoin: buildPath('/rooms/:id/join'),
  Room: buildPath('/rooms/:id'),
  Home: buildPath('/'),
  SlackCallback: buildPath('/slack/callback'),
  Team: buildPath('/teams/:teamId'),
  TeamCourses: buildPath('/teams/:teamId/courses'),
  TeamCourse: buildPath('/teams/:teamId/courses/:courseId'),
  TeamPracticesNew: buildPath('/teams/:teamId/courses/:courseId/practices/new'),
  TeamPractices: buildPath('/teams/:teamId/practices'),
  TeamPractice: buildPath('/teams/:teamId/practices/:practiceId'),
}
