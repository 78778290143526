import { Suspense, lazy } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import { to } from 'src/routes'

import history from 'src/lib/history'

const PageAuthLogin = lazy(() => import('src/pages/AuthLogin'))
const PageAuthLogout = lazy(() => import('src/pages/AuthLogout'))
const PageAuthSlackCallback = lazy(() => import('src/pages/AuthSlackCallback'))
const PageRoomJoin = lazy(() => import('src/pages/RoomJoin'))
const PageRoom = lazy(() => import('src/pages/Room'))
const PageHome = lazy(() => import('src/pages/Home'))
const PageSlackCallback = lazy(() => import('src/pages/SlackCallback'))
const PageTeamCourses = lazy(() => import('src/pages/TeamCourses'))
const PageTeamCourse = lazy(() => import('src/pages/TeamCourse'))
const PageTeamPracticesNew = lazy(() => import('src/pages/TeamPracticesNew'))
const PageTeamPractices = lazy(() => import('src/pages/TeamPractices'))
const PageTeamPractice = lazy(() => import('src/pages/TeamPractice'))

const loader = pug`
  p Loading...
`

function ContainerAppRouter() {
  return pug`
    Router(history=history)
      Suspense(fallback=loader)
        Switch
          Route(exact path=to.AuthLogin() component=PageAuthLogin)

          Route(exact path=to.AuthLogout() component=PageAuthLogout)

          Route(exact path=to.AuthSlackCallback() component=PageAuthSlackCallback)

          Route(exact path=to.RoomJoin() component=PageRoomJoin)

          Route(exact path=to.Room() component=PageRoom)

          Route(exact path=to.Home() component=PageHome)

          Route(exact path=to.SlackCallback() component=PageSlackCallback)

          Redirect(exact from=to.Team() to=to.TeamCourses())

          Route(exact path=to.TeamCourses() component=PageTeamCourses)

          Route(exact path=to.TeamCourse() component=PageTeamCourse)

          Route(exact path=to.TeamPracticesNew() component=PageTeamPracticesNew)

          Route(exact path=to.TeamPractices() component=PageTeamPractices)

          Route(exact path=to.TeamPractice() component=PageTeamPractice)
  `
}

export default ContainerAppRouter
