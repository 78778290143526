import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 62.5%;
  }
  body {
    min-width: 320px; /* stylelint-disable-line declaration-property-unit-blacklist */
    margin: 0;
    color: #252631;
    font-size: 1.4rem;
    line-height: 1.4;
    font-family: system, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body,
  html,
  #root {
    height: 100%;
  }
  #root {
    position: relative;
    z-index: 0;
  }
  button,
  input,
  textarea,
  select {
    font: inherit;
  }

  select, input {
    max-width: 100%;
  }
`

function UICssBaseline() {
  return pug`
    GlobalStyles
  `
}

export default UICssBaseline
